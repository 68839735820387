import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "../styles/header.css"

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `var(--dark-blue)`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        padding: `10px`,
        textAlign: `right`,
        display: `flex`,
      }}
    >
      <p
        style={{
          fontSize: `20px`,
          margin: `5px`,
          marginRight: `auto`,
          marginLeft: `auto`,
        }}
      >
        <Link to="/" className="nav-links">
          Home
        </Link>
        <Link to="/portfolio" className="nav-links">
          Portfolio
        </Link>
        <Link to="/blogs" className="nav-links">
          Blogs
        </Link>
      </p>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
